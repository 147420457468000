import React from "react";
import Svg1 from "../../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-1.svg";
import Svg5 from "../../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-5.svg";
import Svg10 from "../../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-10.svg";
import Svg15 from "../../../../assets/images/vector/pages/report-deals/Pricing/reportDeals-pricing-15.svg";

const PAYWALL_CONTENT = [
  {
    url: "/checkout/?offerId=services_1product",
    svg: Svg1,
    price: "$187",
    quantity: 1,
    text: "Product Report"
  },
  {
    url: "/checkout/?offerId=services_5products",
    svg: Svg5,
    price: "$547",
    quantity: 5,
    text: "Products Report"
  },
  {
    url: "/checkout/?offerId=services_10products",
    svg: Svg10,
    price: "$997",
    quantity: 10,
    text: "Products Report"
  },
  {
    url: "/checkout/?offerId=services_15products",
    svg: Svg15,
    price: "$1,397",
    quantity: 15,
    text: "Products Report"
  }
];

export default PAYWALL_CONTENT;
