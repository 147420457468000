import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import Paywall from "../../components/iframe/wa-individual-report-paywall/Paywall";
import "../../components/iframe/wa-individual-report-paywall/index.scss";

const PgIWIRP = () => {
  const { addProps, isRendered, paramsLink } = useQueryWaIframeParams();

  return isRendered ? (
    <Layout withTagManager={false} className="PgIWIRP">
      <h2 className="PgIWIRP__title">Order your Individual Report now!</h2>
      <Paywall {...addProps} paramsLink={paramsLink} />
    </Layout>
  ) : null;
};

export default PgIWIRP;
