import React from "react";
import PAYWALL_CONTENT from "./index.content";
import PaywallOffer from "./PaywallOffer";
import "./index.scss";

const Paywall = ({ software, checkoutLanguage, paramsLink }) => {
  const eventCategory = `${software ? software : "LANDING"}|Pricing`;
  const updateLink = (link) => {
    paramsLink && (link += paramsLink);
    return checkoutLanguage ? `/${checkoutLanguage}${link}` : link;
  };
  return (
    <section className="PgIWIRP-Paywall">
      {PAYWALL_CONTENT.map(({ url, svg, price, quantity, text }, i) => (
        <PaywallOffer
          key={i}
          url={updateLink(url)}
          svg={svg}
          price={price}
          quantity={quantity}
          text={text}
          eventCategory={eventCategory}
        />
      ))}
    </section>
  );
};

export default Paywall;
